import shopifyWarehouseDefaults from '../shopify-warehouse-generic/config.js';

globalThis.Convermax.fitmentFieldsTranslation = {
  Year: 'Jahr',
  Make: 'Marke',
  Model: 'Modell',
};

export default {
  includes: ['shopify-warehouse-generic'],
  ...shopifyWarehouseDefaults,
  searchPageUrl: '/search',
  Widgets: [
    ...shopifyWarehouseDefaults.Widgets.filter((w) => !['SearchHeader', 'VerifyFitment'].includes(w.name)),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Beste Übereinstimmung',
        'title': 'Von, A-Z',
        'title:desc': 'Von, Z-A',
        'collection_sort': 'Hervorgehoben',
        'price': 'Preis, Niedrig bis Hoch',
        'price:desc': 'Preis, Hoch to Niedrig',
        'published_at': 'Datum, Von Alt zu Neu',
        'published_at:desc': 'Datum, Neu zu alt',
      },
      sortSelectClass: 'select__select caption-large',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/collapsibleVerifyFitment',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
  ],
};
